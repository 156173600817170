import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'
import { useMutation } from '@tanstack/react-query'

import { apiTicketsChange, TicketDetail } from '@klr/api-connectors'

import { RqKeysEnum, RqKeysEnumAction } from '../../../constants/rq-keys.enum'
import { uiActions } from '../../ui'

export const useUpdateTicket = (successCallback?: (val: TicketDetail) => void) => {
  const { t } = useTranslation()
  const dispatch = useDispatch()

  return useMutation({
    mutationKey: [RqKeysEnum.TICKET_DETAIL, RqKeysEnumAction.UPDATE],
    mutationFn: apiTicketsChange,
    onSuccess: (response) => {
      if (successCallback) {
        successCallback(response.data.item)
      }

      dispatch(uiActions.closeDetailModal())
      toast.success(t('Messages.ticket_successfully_updated'))
    },
    onError: () => {
      toast.error(t('Messages.failed_to_update_ticket'))
    },
  })
}
