import { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Grid } from '@mui/material'

import { Drawer, SearchPhoneField } from '@klr/shared'

import { HeaderDrawer } from '../../../../components'
import { clearAllSearchPassenger, useSearchPassengerUIStore } from '../../store'

import { OrderList } from './components/OrderList'

export const DrawerSearchPassenger = () => {
  const { t } = useTranslation()
  const { phoneNumber, showDrawer } = useSearchPassengerUIStore()

  const [searchPhone, setSearchPhone] = useState(phoneNumber)

  const closeDrawer = () => {
    clearAllSearchPassenger()
    setSearchPhone('')
  }

  return (
    <Drawer anchor="left" open={showDrawer} onClose={closeDrawer}>
      <HeaderDrawer title={t('Common.search_passenger')} closeDrawer={closeDrawer} />
      <Grid container>
        <Grid item xs={12} mb={2}>
          <SearchPhoneField defaultPhone={phoneNumber} setSearchPhone={setSearchPhone} />
        </Grid>

        <Grid item xs={12}>
          <OrderList searchPhone={searchPhone} />
        </Grid>
      </Grid>
    </Drawer>
  )
}
