import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { apiTicketsReturn, OrderDetail, ordersQueryKeys } from '@klr/api-connectors'

import { RqKeysEnum, RqKeysEnumAction } from '../../../constants/rq-keys.enum'

export const useReturnTicket = (orderId: OrderDetail['id']) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const { mutate, isLoading } = useMutation(apiTicketsReturn, {
    onSuccess: (response) => {
      queryClient.invalidateQueries(ordersQueryKeys.detail(orderId)).then(() => {
        toast.success(
          t('Messages.ticket_returned_with_sum', { sum: response.data.item.price_returned })
        )
      })
    },
    onError: () => {
      toast.error(t('Messages.failed_to_return_ticket'))
    },
    mutationKey: [RqKeysEnum.TICKET_DETAIL, RqKeysEnumAction.UPDATE, orderId],
  })

  return {
    mutate,
    isLoading,
  }
}
