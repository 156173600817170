import { forwardRef, Ref, useEffect, useImperativeHandle, useMemo, useState } from 'react'
import { useFormContext } from 'react-hook-form'
import { CircularProgress, Grid } from '@mui/material'

import { SeatCategory } from '@klr/api-connectors'

import { useFetchPrice } from '../../../../../../tickets/hooks'
import { useSchemePassengersStore } from '../../../../../hooks/store'
import { AvailablePlace } from '../../../../../validations'

import { PriceEditForm } from './components/PriceEditForm'
import { PriceView } from './components/PriceView'

type Props = {
  category?: SeatCategory | null
  stationFromId: number
  stationToId: number
  currencyId: number
  promotionValue: string
  index: number
  mainTripId: number | null
  promotionId: number
}

export const PriceTicket = forwardRef(
  (
    {
      category,
      stationFromId,
      stationToId,
      promotionId,
      promotionValue,
      currencyId,
      mainTripId,
      index,
    }: Props,
    ref: Ref<{ parentSubmit: (value: string) => void }>
  ) => {
    const [editMode, setEditMode] = useState(false)

    const transportId = useSchemePassengersStore((state) => state.transportId)

    const { register, setValue, clearErrors, watch } = useFormContext()

    const hasPriceFieldName = `tickets[${index}].hasPrice`
    const customPriceFieldName = `tickets[${index}].price`
    const availablePlace: AvailablePlace | null = watch(`tickets[${index}].place`)

    // if we have a category then we've selected place from a scheme,
    // and we need to get a surcharge price in request
    const schemeCategoryPlace = useMemo(() => {
      const place = Number(availablePlace?.place)

      if (!category || Number.isNaN(place)) return

      return place
    }, [availablePlace?.place, category])

    const { price, setPrice, fetchPrice, isLoading } = useFetchPrice({
      stationFromId,
      stationToId,
      promotionId,
      currencyId,
      mainTripId,
      place: schemeCategoryPlace,
      transportId,
    })

    const surchargePrice = useMemo(() => {
      return (
        (category && price?.seat_prices.find((item) => item.category === category)?.price_extra) ??
        0
      )
    }, [category, price])

    useEffect(() => {
      fetchPrice()
    }, [fetchPrice])

    useEffect(() => {
      if (price?.price_full) {
        setValue(hasPriceFieldName, true)
        setValue(customPriceFieldName, price.price_full)
        clearErrors(hasPriceFieldName)
      } else {
        setValue(hasPriceFieldName, false)
        setValue(customPriceFieldName, 0)
      }
    }, [clearErrors, customPriceFieldName, hasPriceFieldName, price?.price_full, setValue])

    useImperativeHandle(ref, () => ({
      parentSubmit() {
        if (promotionValue && promotionId === 24) {
          fetchPrice(promotionValue)
        }
      },
    }))

    if (isLoading) {
      return <CircularProgress />
    }

    return (
      <Grid item xs={surchargePrice ? 8 : 7}>
        <input hidden type="checkbox" {...register(hasPriceFieldName)} />

        {editMode ? (
          <PriceEditForm
            price={price}
            setPrice={setPrice}
            setEditMode={setEditMode}
            surchargePrice={surchargePrice}
          />
        ) : (
          <PriceView price={price} setEditMode={setEditMode} surchargePrice={surchargePrice} />
        )}
      </Grid>
    )
  }
)
