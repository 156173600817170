import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import { SheetDataWithCurrent, SheetTransport } from '@klr/api-connectors'

import { transformTransportPlaces } from './helpers'
import {
  addMessageInfo,
  changeTicketsReducers,
  createTicket,
  deleteTicket,
  informingDriver,
  messagesReducers,
  reconciliationTicket,
  segmentPlacesReducers,
  stationReducers,
  toggleSubList,
  transferPlaceReducers,
  transferTransportReducers,
  tripReducers,
} from './reducers'
import { SheetPayloadCreateDrawer } from './sheetDrawers'
import { SheetsState } from './sheetState'

const initialState: SheetsState = {
  mainTrip: null,
  promotions: [],
  lostTickets: null,
  transports: [],
  selectTransport: null,
  idDetail: null,
  stations: [],
  availableSegmentPlaces: [],
  transferPlaces: [],
  transferTransport: [],
  transferTransportPlace: null,
  transferTransportPlaces: [],
  placeForMessages: [],
  isAllowedSync: true,
  isShowCreateDrawer: false,
  drawerCreatePayload: null,
}

const slice = createSlice({
  name: 'SHEETS',
  initialState,
  reducers: {
    saveSheet: (state: SheetsState, action: PayloadAction<SheetDataWithCurrent>) => {
      const { main_trip, transports, lost_tickets, included, currentTransportIndex } =
        action.payload

      const transportIndex =
        currentTransportIndex && currentTransportIndex < transports.length
          ? currentTransportIndex
          : 0

      state.mainTrip = main_trip
      state.transports = transports.map(transformTransportPlaces)
      state.lostTickets = lost_tickets
      // Потрібно для відображення вибраного або першого табу в відомості
      state.selectTransport = {
        id: transports[transportIndex].id,
        tripId: transports[transportIndex].trip.id,
        placeConstructor: transports[transportIndex].place_constructor,
      }

      if (included?.promotions) {
        state.promotions = included.promotions
      }
    },
    clearSheet: (state: SheetsState) => {
      state.mainTrip = null
      state.promotions = []
      state.lostTickets = null
      state.transports = []
      state.selectTransport = null
    },
    clearTransport: (state: SheetsState) => {
      state.lostTickets = null
    },
    selectTransport: (state: SheetsState, action: PayloadAction<SheetTransport>) => {
      state.selectTransport = {
        id: action.payload.id,
        tripId: action.payload.trip.id,
        placeConstructor: action.payload.place_constructor,
      }
    },
    toggleAllowedSync: (state: SheetsState, action: PayloadAction<boolean>) => {
      state.isAllowedSync = action.payload
    },
    showCreateDrawer: (state: SheetsState, action: PayloadAction<SheetPayloadCreateDrawer>) => {
      state.isShowCreateDrawer = true
      state.drawerCreatePayload = action.payload
    },
    closeCreateDrawer: (state: SheetsState) => {
      state.isShowCreateDrawer = false
      state.drawerCreatePayload = null
    },
    addMessageInfo,
    toggleSubList,
    createTicket,
    deleteTicket,
    reconciliationTicket,
    informingDriver,
    ...messagesReducers,
    ...segmentPlacesReducers,
    // TicketChange
    ...changeTicketsReducers,
    ...stationReducers,
    // Transfer
    ...transferTransportReducers,
    ...transferPlaceReducers,
    // Trip
    ...tripReducers,
  },
})

export default slice.reducer

// Action Creators Async
export const sheetsActions = slice.actions
