import { AppBar, Box, Divider, IconButton, Stack, Toolbar } from '@mui/material'
import { useTheme } from '@mui/material/styles'

import { AccountPopover, InviteLinkModalButton } from '@klr/auth'
import {
  CustomerPanel,
  setSearchCustomerPhoneNumber,
  toggleSearchCustomerDrawer,
} from '@klr/customers'
import {
  bgBlur,
  HEADER,
  Logo,
  NAV,
  SettingsMenu,
  SvgColor,
  useOffSetTop,
  useResponsive,
  useSettings,
} from '@klr/shared'

import { environments } from '../../../environments'
import { InitChangelogButton, InitFeedbackButton } from '../../../features/featurebase'

type Props = {
  onOpenNav?: VoidFunction
}

export const Header = ({ onOpenNav }: Props) => {
  const theme = useTheme()
  const { themeLayout } = useSettings()

  const isNavHorizontal = themeLayout === 'horizontal'

  const isNavMini = themeLayout === 'mini'

  const lgUp = useResponsive('up', 'lg')

  const offset = useOffSetTop(HEADER.H_DESKTOP)

  const offsetTop = offset && !isNavHorizontal

  const showSearchCustomer = (payload: string) => {
    setSearchCustomerPhoneNumber(payload)
    toggleSearchCustomerDrawer(true)
  }

  const renderContent = (
    <>
      <Box sx={{ mr: 1 }}>{lgUp && isNavHorizontal && <Logo />}</Box>

      {!lgUp && (
        <IconButton onClick={onOpenNav}>
          <SvgColor src="/assets/icons/navbar/ic_menu_item.svg" />
        </IconButton>
      )}

      <Box ml={15}>
        <CustomerPanel onShowSearchCustomer={showSearchCustomer} />
      </Box>

      <Stack
        flexGrow={1}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
        spacing={{ xs: 0.5, sm: 1 }}
      >
        <InitChangelogButton />

        <InviteLinkModalButton marketplaceURL={environments.marketplaceURL} />

        <SettingsMenu />

        <AccountPopover
          slotAdditional={
            <>
              <Divider sx={{ borderStyle: 'dashed' }} />

              <InitFeedbackButton />
            </>
          }
        />
      </Stack>
    </>
  )

  return (
    <AppBar
      sx={{
        height: HEADER.H_MOBILE,
        zIndex: theme.zIndex.appBar + 1,
        ...bgBlur({
          color: theme.palette.background.default,
        }),
        transition: theme.transitions.create(['height'], {
          duration: theme.transitions.duration.shorter,
        }),
        ...(lgUp && {
          width: `calc(100% - ${NAV.W_VERTICAL + 1}px)`,
          height: HEADER.H_DESKTOP,
          ...(offsetTop && {
            height: HEADER.H_DESKTOP_OFFSET,
          }),
          ...(isNavHorizontal && {
            width: 1,
            bgcolor: 'background.default',
            height: HEADER.H_DESKTOP_OFFSET,
            borderBottom: `dashed 1px ${theme.palette.divider}`,
          }),
          ...(isNavMini && {
            width: `calc(100% - ${NAV.W_MINI + 1}px)`,
          }),
        }),
      }}
    >
      <Toolbar
        sx={{
          height: 1,
          px: { lg: 5 },
        }}
      >
        {renderContent}
      </Toolbar>
    </AppBar>
  )
}
