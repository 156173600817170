import toast from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useMutation } from '@tanstack/react-query'

import { apiTicketsConfirm, TicketReqChange } from '@klr/api-connectors'

export const useMutateConfirmTicket = () => {
  const { t } = useTranslation()

  return useMutation(
    ({ ticketId, data }: TicketReqChange) =>
      apiTicketsConfirm({
        ticketId,
        data,
      }),
    {
      onSuccess: () => {
        toast.success(t('Orders.ticket_confirmed'))
      },
    }
  )
}
