import { SagaIterator } from '@redux-saga/core'
import { all, call, takeEvery } from 'redux-saga/effects'

import { types } from '../types'

import {
  createWorker,
  customReturnPercentWorker,
  deleteWorker,
  fetchAvailableSeatsWorker,
  fetchDetailWorker,
  fetchReturnConditionsWorker,
  fetchSeatsForTransferWorker,
  printPdfTicket,
  returnWorker,
  toggleSubListWorker,
  transferWorker,
} from './workers'

function* watchToggleSubList(): SagaIterator {
  yield takeEvery(types.TOGGLE_SUB_LIST, toggleSubListWorker)
}

function* watchCreate(): SagaIterator {
  yield takeEvery(types.CREATE_ITEM, createWorker)
}

function* watchDelete(): SagaIterator {
  yield takeEvery(types.DELETE_ITEM, deleteWorker)
}

function* watchFetchDetail(): SagaIterator {
  yield takeEvery(types.FETCH_DETAIL, fetchDetailWorker)
}

function* watchPrintPdfTicket(): SagaIterator {
  yield takeEvery(types.PRINT_PDF_TICKET, printPdfTicket)
}

function* watchFetchSeatsForTransfer(): SagaIterator {
  yield takeEvery(types.FETCH_SEATS_FOR_TRANSFER, fetchSeatsForTransferWorker)
}

function* watchFetchAvailableSeats(): SagaIterator {
  yield takeEvery(types.FETCH_AVAILABLE_SEATS, fetchAvailableSeatsWorker)
}

function* watchTransfer(): SagaIterator {
  yield takeEvery(types.TRANSFER, transferWorker)
}

function* watchReturn(): SagaIterator {
  yield takeEvery(types.RETURN, returnWorker)
}

function* watchFetchReturnConditions(): SagaIterator {
  yield takeEvery(types.FETCH_RETURN_CONDITIONS, fetchReturnConditionsWorker)
}

function* watchCustomReturnPercent(): SagaIterator {
  yield takeEvery(types.CUSTOM_RETURN_PERCENT, customReturnPercentWorker)
}

export function* watchTickets(): SagaIterator {
  yield all([
    call(watchCreate),
    call(watchFetchDetail),
    call(watchPrintPdfTicket),
    call(watchDelete),
    call(watchToggleSubList),
    call(watchFetchSeatsForTransfer),
    call(watchFetchAvailableSeats),
    call(watchTransfer),
    call(watchReturn),
    call(watchFetchReturnConditions),
    call(watchCustomReturnPercent),
  ])
}
