import { useTranslation } from 'react-i18next'
import { useDebounce } from 'react-use'
import { Clear as ClearIcon, Search as SearchIcon } from '@mui/icons-material'
import { IconButton, TextField } from '@mui/material'

interface ClientSearchFieldProps {
  inputValue: string
  setInputValue(val: string): void
  setSearchValue(val: string): void
}

export const CustomerSearchField = ({
  inputValue,
  setInputValue,
  setSearchValue,
}: ClientSearchFieldProps) => {
  const { t } = useTranslation()

  useDebounce(() => setSearchValue(inputValue), 600, [inputValue])

  return (
    <TextField
      placeholder={`${t('Customers.search')}...`}
      value={inputValue}
      autoFocus
      onChange={(event) => setInputValue(event.target.value)}
      fullWidth
      InputProps={{
        startAdornment: <SearchIcon />,
        endAdornment: (
          <IconButton
            title={t('Common.clear')}
            aria-label={t('Common.clear')}
            size="small"
            style={{
              visibility: inputValue ? 'visible' : 'hidden',
            }}
            onClick={() => setInputValue('')}
            color="primary"
          >
            <ClearIcon />
          </IconButton>
        ),
      }}
    />
  )
}
