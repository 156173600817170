import { useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { LoadingButton } from '@mui/lab'
import { Button, DialogActions, DialogContent, Grid } from '@mui/material'
import { DateTimePicker } from '@mui/x-date-pickers-pro'
import { useQueryClient } from '@tanstack/react-query'
import { isValid } from 'date-fns'

import { TicketDetail } from '@klr/api-connectors'
import { useActionPaidReserve } from '@klr/order-ticket-toolkit'
import { UKR_DATE_TIME_FORMAT } from '@klr/shared'

type Props = {
  onClose: VoidFunction
  ticketId: TicketDetail['id']
}

export const DialogPaidReserveInternal = ({ onClose, ticketId }: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()

  const [selectedDate, setSelectedDate] = useState<Date | null>(new Date())

  const { mutateAsync, isLoading } = useActionPaidReserve({
    ticketId,
  })

  const handlePaidReserve = () => {
    if (!(selectedDate && isValid(selectedDate))) {
      toast.error('Введіть коректну дату оплати.')
      return
    }

    mutateAsync({
      paidAt: selectedDate,
    }).then(() => {
      queryClient.invalidateQueries(['sheet']).then(onClose)
    })
  }

  return (
    <>
      <DialogContent>
        <Grid container>
          <Grid item xs={6}>
            <DateTimePicker
              value={selectedDate}
              onChange={setSelectedDate}
              label="Оберіть дату та час оплати"
              format={UKR_DATE_TIME_FORMAT}
              maxDate={new Date()}
              sx={{ mt: 1 }}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>{t('Common.cancel')}</Button>
        <LoadingButton loading={isLoading} onClick={handlePaidReserve} variant="contained">
          {t('Common.save')}
        </LoadingButton>
      </DialogActions>
    </>
  )
}
