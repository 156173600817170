import { create } from 'zustand'

import { SheetPlace } from '@klr/api-connectors'

interface PlacesForSorryBonusesStore {
  showModal: boolean
  dataSource: SheetPlace[]
}

const initialState: PlacesForSorryBonusesStore = {
  showModal: false,
  dataSource: [],
}

export const usePlacesForSorryBonusesStore = create<PlacesForSorryBonusesStore>()(
  (_) => initialState
)

export const setDataPlacesForSorryBonuses = (payload: SheetPlace[]) =>
  usePlacesForSorryBonusesStore.setState({ dataSource: payload, showModal: true })

export const clearDataPlacesForSorryBonuses = () =>
  usePlacesForSorryBonusesStore.setState(initialState)
