import { useEffect, useMemo, useState } from 'react'
import { Check as CheckIcon, Mail as MailIcon, Trash2 as TrashIcon } from 'react-feather'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { AccountBalanceWalletOutlined as AccountBalanceWalletOutlinedIcon } from '@mui/icons-material'
import { Button, Grid, SvgIcon } from '@mui/material'
import { useQueryClient } from '@tanstack/react-query'

import { OrderDetail, ordersQueryKeys, StatusTicketEnum, TicketDetail } from '@klr/api-connectors'
import { useAuth } from '@klr/auth'
import { CustomReturnPercent, ReturnFieldControl, TrackingBusInfo } from '@klr/order-ticket-toolkit'
import { copyToClipboard, hasConfirmTicket } from '@klr/shared'

import { environments } from '../../../../../environments'
import { generateSheetPlace } from '../../../../sheets/helpers'
import { useMutateDispatchToMessage } from '../../../../sheets/hooks'
import { useHandlers as useTicketsHandlers, useMutateConfirmTicket } from '../../../hooks'
import { ticketsSelectors } from '../../../index'
import { ticketsActionsAsync } from '../../../slice'
import { getAllowedActionsTickets } from '../../../tickets.utils'
import { DialogPaidReserve } from '../../DialogPaidReserve'

import { TicketPriceInfo } from './TicketPriceInfo'

type Props = {
  showSendMessage: boolean
  dataSource: TicketDetail
  orderStatus: StatusTicketEnum
  orderId: OrderDetail['uuid']
  isLoading: boolean
  fromList: boolean
}

export const ActionsTicket = ({
  showSendMessage,
  dataSource,
  orderStatus,
  orderId,
  isLoading: isLoadingProp,
  fromList,
}: Props) => {
  const { t } = useTranslation()
  const queryClient = useQueryClient()
  const dispatch = useDispatch()

  const { user, permissions } = useAuth()

  const returnCondition = useSelector(ticketsSelectors.getReturnCondition)

  const defaultCustomPercent = useMemo(() => {
    if (returnCondition) {
      const { return_to_client, system_cash } = returnCondition

      return Math.floor((return_to_client / (system_cash + return_to_client)) * 100)
    }

    return 0
  }, [returnCondition])

  const [isShowAtPaid, setShowAtPaid] = useState<number | null>(null) // TicketId

  // Внести дані про оплату
  const handlePayReserved = (): void => {
    setShowAtPaid(dataSource.id)
  }

  const { handleDispatchToMessage } = useMutateDispatchToMessage()

  const _handleDispatchToMessage = () => {
    const sheetPlace = generateSheetPlace(dataSource)

    handleDispatchToMessage([sheetPlace])
  }

  const {
    handleRemoveTicket,
    handleReturnTicket,
    handleCustomReturnPercent,
    isLoading: isReturning,
  } = useTicketsHandlers({
    ticketId: dataSource.id,
    ticketNumber: dataSource.ticket_number,
    orderId: dataSource.order_id,
  })

  const mutateConfirmTicket = useMutateConfirmTicket()

  const handleConfirmTicket = () => {
    mutateConfirmTicket
      .mutateAsync({
        ticketId: dataSource.id,
        data: {
          confirmed: true,
        },
      })
      .then(() =>
        queryClient.invalidateQueries(
          fromList
            ? ordersQueryKeys.ordersList({ ticket_phone: dataSource.phone })
            : ordersQueryKeys.detail(dataSource.order_id)
        )
      )
  }

  const allowActions = useMemo(
    () =>
      getAllowedActionsTickets({
        isEarlyBooking: dataSource.is_early_booking,
        orderStatus,
        status: dataSource.status,
        canReturnEBTicket: permissions.canReturnEBTicket,
      }),
    [dataSource.is_early_booking, dataSource.status, orderStatus, permissions.canReturnEBTicket]
  )

  useEffect(() => {
    if (!allowActions.hasReturnedTicket) {
      dispatch(ticketsActionsAsync.fetchReturnConditionsAsync(dataSource.id))
    }
  }, [allowActions.hasReturnedTicket, dataSource.id, dispatch])

  const isLoading = isLoadingProp || isReturning || mutateConfirmTicket.isLoading

  const _renderActions = () => {
    const fields = [
      {
        isShow: allowActions.hasPayReserved,
        label: t('Orders.enter_payment_info'),
        handle: handlePayReserved,
        icon: (
          <SvgIcon fontSize="small">
            <AccountBalanceWalletOutlinedIcon />
          </SvgIcon>
        ),
      },
      {
        isShow: allowActions.hasPayReserved,
        label: t('Orders.pay_through_liqpay'),
        handle: () => {
          toast(t('Messages.method_not_ready'))
        },
        icon: (
          <SvgIcon fontSize="small">
            <AccountBalanceWalletOutlinedIcon />
          </SvgIcon>
        ),
      },
      {
        isShow: allowActions.hasDeleteTicket,
        label: t('Orders.remove_ticket'),
        handle: handleRemoveTicket,
        icon: (
          <SvgIcon fontSize="small">
            <TrashIcon />
          </SvgIcon>
        ),
      },

      {
        isShow:
          !dataSource.confirmed && hasConfirmTicket(dataSource.status, dataSource.can_reserve),
        label: t('Orders.confirm_ticket'),
        handle: handleConfirmTicket,
        icon: (
          <SvgIcon fontSize="small">
            <CheckIcon />
          </SvgIcon>
        ),
      },
      {
        isShow: !!user?.has_payment_system && allowActions.isShowPaymentLink,
        label: t('Orders.payment_link'),
        handle: () =>
          copyToClipboard(
            `${environments.marketplaceURL}/booking-payment/${orderId}`,
            t('Messages.link_copied')
          ),
      },
      showSendMessage && {
        isShow: true,
        label: t('Orders.send_message'),
        handle: _handleDispatchToMessage,
        icon: (
          <SvgIcon fontSize="small">
            <MailIcon />
          </SvgIcon>
        ),
      },
    ].filter(Boolean)

    return fields.map(
      (item, idx) =>
        item &&
        item.isShow && (
          <Grid item md={6} key={idx}>
            <Button
              variant="outlined"
              onClick={item.handle}
              startIcon={item.icon}
              disabled={isLoading}
              fullWidth
            >
              {item.label}
            </Button>
          </Grid>
        )
    )
  }

  return (
    <>
      <Grid container alignItems="center" spacing={2}>
        {_renderActions()}
        {!allowActions.hasReturnedTicket && (
          <Grid item xs={12} md={6}>
            <TrackingBusInfo dataSource={dataSource} />
          </Grid>
        )}

        {allowActions.hasReturnTicket && (
          <ReturnFieldControl
            isLoading={isLoading}
            onClick={handleReturnTicket}
            label={t('Orders.return_ticket')}
          />
        )}

        <TicketPriceInfo
          cashbackPayment={dataSource.cashback_payment}
          price={dataSource.price}
          price_extra={dataSource.price_extra}
          discount={dataSource.discount}
          handleReturn={handleReturnTicket}
          isLoadingHandler={isLoading}
          currencyISO={dataSource.currency.iso}
          hasReturnTicket={allowActions.hasReturnTicket}
          hasReturnedTicket={allowActions.hasReturnedTicket}
          hasLiqpay={allowActions.hasLiqPay}
          ticketId={dataSource.id}
          liqpayInfo={dataSource.liqpay_return_info}
          returnCondition={returnCondition}
        />
      </Grid>
      {allowActions.hasReturnTicket && (
        <Grid>
          <CustomReturnPercent
            defaultCustomPercent={defaultCustomPercent}
            handleCustomReturnPercent={handleCustomReturnPercent}
            isLoading={isLoading}
          />
        </Grid>
      )}

      <DialogPaidReserve ticketId={isShowAtPaid} onClose={() => setShowAtPaid(null)} />
    </>
  )
}
