import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { List, Typography } from '@mui/material'

import { OrderDetail, StatusTicketEnum } from '@klr/api-connectors'
import {
  OrderItem,
  statusIconDataList,
  StatusIconDataType,
  useOrderDetailContext,
} from '@klr/order-ticket-toolkit'

import { useCustomerStore } from '../../../../../store'
import { StyledCustomerCard } from '../customer-card.styles'

export const CustomerShortOrderList = () => {
  const { t } = useTranslation()
  const customerOrders = useCustomerStore((state) => state.orders)

  const { showOrderDetail } = useOrderDetailContext()

  const handleShowOrder = useCallback(
    (orderId: OrderDetail['id']) => {
      showOrderDetail(orderId)
    },
    [showOrderDetail]
  )

  const getStatusIconData = useCallback(
    (status: StatusTicketEnum): StatusIconDataType | undefined => {
      return statusIconDataList.find((item) => item.status === status)
    },
    []
  )

  return (
    <StyledCustomerCard>
      <Typography sx={{ px: 2 }} variant="h6">
        {t('Orders.order')}
      </Typography>

      {customerOrders.length === 0 ? (
        <Typography variant="body2" color="text.disabled">
          Замовлень не знайдено
        </Typography>
      ) : (
        <List sx={{ mb: 6 }}>
          {customerOrders.map((order) => (
            <OrderItem
              key={order.id}
              order={order}
              statusIconData={getStatusIconData(order.status)}
              onClickHandler={handleShowOrder}
            />
          ))}
        </List>
      )}
    </StyledCustomerCard>
  )
}
