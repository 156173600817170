import { create } from 'zustand'
import { devtools } from 'zustand/middleware'

import { Floor, Seat, SheetTransport, SheetTransportScheme, TripItem } from '@klr/api-connectors'
import { createSelectors } from '@klr/shared'

import {
  addCollapsedPassenger,
  getCandidateSeatAndRestFromAvailable,
  transformAvailableSeatsByDisabling,
  transformPassengersByRemoving,
  transformSchemeBySelectedPlace,
} from '../../helpers/scheme-passengers.utils'
import { AvailablePlace, PlaceCollapse } from '../../validations'

interface SchemePassengersStore {
  transportId: SheetTransport['id']
  transportTripId: TripItem['id']
  mainTripId: TripItem['id']
  schemeId: SheetTransportScheme['id']
  scheme: Floor[] | null
  passengers: PlaceCollapse[]
  availableSeats: AvailablePlace[]
  isAvailableAdd: boolean
  transportPlaces: SheetTransport['places']
}

const initialState = {
  transportId: 0,
  transportTripId: 0,
  mainTripId: 0,
  schemeId: 0,
  scheme: null,
  passengers: [],
  availableSeats: [],
  isAvailableAdd: true,
  transportPlaces: [],
}

export const useSchemePassengersStore = createSelectors(
  create<SchemePassengersStore>()(
    devtools((_) => initialState, {
      name: 'schemePassengersStore',
      enabled: process.env.NODE_ENV === 'development',
    })
  )
)

export const setInitialData = ({
  availableSeats,
  scheme,
  transportId,
  transportTripId,
  mainTripId,
  transportPlaces,
  schemeId,
}: Pick<
  SchemePassengersStore,
  | 'availableSeats'
  | 'scheme'
  | 'transportId'
  | 'transportTripId'
  | 'mainTripId'
  | 'transportPlaces'
  | 'schemeId'
>) =>
  useSchemePassengersStore.setState(() => {
    const enabledAvailableSeats = availableSeats.filter((item) => !item.disabled)

    const [candidate, ...rest] = getCandidateSeatAndRestFromAvailable(enabledAvailableSeats)

    if (!candidate) {
      return {
        ...initialState,
        isAvailableAdd: false,
      }
    }

    return {
      transportPlaces,
      availableSeats: transformAvailableSeatsByDisabling(availableSeats, candidate.place, 'true'),
      isAvailableAdd: !!rest.length,
      passengers: [
        {
          place: candidate.place,
          collapse: true,
        },
      ],
      scheme: transformSchemeBySelectedPlace(scheme, candidate.place),
      transportId,
      transportTripId,
      mainTripId,
      schemeId,
    }
  })

export const clearSchemePassengersStore = () =>
  useSchemePassengersStore.setState(() => initialState)

export const setSchemeToggle = (payload: Seat) =>
  useSchemePassengersStore.setState((state) => {
    // if only one passenger is selected, we can't unselect it
    if (payload.selected && state.passengers.length < 2) {
      return state
    }

    return {
      availableSeats: transformAvailableSeatsByDisabling(
        state.availableSeats,
        payload.place,
        'inverse'
      ),
      passengers: payload.selected
        ? transformPassengersByRemoving(state.passengers, payload)
        : addCollapsedPassenger(state.passengers, payload.place),
      scheme: transformSchemeBySelectedPlace(state.scheme, payload.place),
    }
  })

export const addPassenger = (payload: AvailablePlace) =>
  useSchemePassengersStore.setState((state) => {
    return {
      passengers: addCollapsedPassenger(state.passengers, payload.place),
      availableSeats: transformAvailableSeatsByDisabling(
        state.availableSeats,
        payload.place,
        'true'
      ),
      scheme: transformSchemeBySelectedPlace(state.scheme, payload.place),
    }
  })

export const removePassenger = (index: number) =>
  useSchemePassengersStore.setState((state) => {
    const removingPlace = state.passengers[index].place

    return {
      passengers: state.passengers
        .filter((_, i) => i !== index)
        .map((item, _, arrayState) =>
          arrayState.length === 1 ? { ...item, collapse: true } : item
        ),
      availableSeats: transformAvailableSeatsByDisabling(
        state.availableSeats,
        removingPlace,
        'false'
      ),
      isAvailableAdd: true,
      scheme: transformSchemeBySelectedPlace(state.scheme, removingPlace),
    }
  })

export const togglePassengerCollapse = (index: number) =>
  useSchemePassengersStore.setState((state) => ({
    passengers: state.passengers.map((item, idx) => ({
      ...item,
      collapse: idx === index ? !item.collapse : false,
    })),
  }))

export const changePassengerPlace = (
  index: number,
  newPlace: AvailablePlace,
  oldPlace: AvailablePlace | null
) =>
  useSchemePassengersStore.setState((state) => ({
    passengers: [
      ...state.passengers.slice(0, index),
      {
        ...state.passengers[index],
        place: newPlace.place,
      },
      ...state.passengers.slice(index + 1),
    ],
    availableSeats: state.availableSeats.map((seat) => {
      if (seat.place === newPlace.place) {
        return {
          ...seat,
          disabled: true,
        }
      }

      if (oldPlace && seat.place === oldPlace.place) {
        return {
          ...seat,
          disabled: false,
        }
      }

      return seat
    }),
    scheme: transformSchemeBySelectedPlace(state.scheme, newPlace.place, oldPlace?.place),
  }))

export const setAvailableAdd = (payload: boolean) =>
  useSchemePassengersStore.setState(() => ({ isAvailableAdd: payload }))
