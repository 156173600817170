import { useEffect, useState } from 'react'
import { X as CloseIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import { Grid, IconButton, Tooltip, Typography } from '@mui/material'

import { Drawer } from '@klr/shared'

import { clearAllSearchCustomer, useSearchCustomerUIStore } from '../../store'

import { CustomerList } from './components/CustomerList'
import { CustomerSearchField } from './components/CustomerSearchField'

export const DrawerSearchCustomer = () => {
  const { t } = useTranslation()
  const { phoneNumber: phoneNumberPayload, showDrawer } = useSearchCustomerUIStore()

  const [phoneNumber, setPhoneNumber] = useState('')
  const [searchPhone, setSearchPhone] = useState('')

  const closeDrawer = () => {
    clearAllSearchCustomer()
    setPhoneNumber('')
    setSearchPhone('')
  }

  useEffect(() => {
    setPhoneNumber(phoneNumberPayload)
    setSearchPhone(phoneNumberPayload)
  }, [phoneNumberPayload])

  return (
    <Drawer anchor="left" open={showDrawer} onClose={closeDrawer}>
      <Grid sx={{ mb: 2 }} container justifyContent="space-between" alignItems="center">
        <Grid item md={11}>
          <Typography variant="h4">{t('Customers.search')}</Typography>
        </Grid>
        <Grid item md={1}>
          <Tooltip title={t('Common.close')}>
            <IconButton aria-label={t('Common.close')} onClick={closeDrawer} size="large">
              <CloseIcon fontSize="large" />
            </IconButton>
          </Tooltip>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} mb={2}>
          <CustomerSearchField
            inputValue={phoneNumber}
            setInputValue={setPhoneNumber}
            setSearchValue={setSearchPhone}
          />
        </Grid>

        <Grid item xs={12}>
          <CustomerList onClose={closeDrawer} phoneNumber={searchPhone} />
        </Grid>
      </Grid>
    </Drawer>
  )
}
