import { useTranslation } from 'react-i18next'
import {
  ClearOutlined as DeleteIcon,
  PersonSearchOutlined as PersonSearchIcon,
} from '@mui/icons-material'
import { Button, ButtonGroup, IconButton, Stack, SvgIcon, Tooltip, Typography } from '@mui/material'

import { CustomerDTO } from '@klr/api-connectors'
import { ClientIcon } from '@klr/shared'

import {
  clearCustomerData,
  setCustomerCardId,
  toggleCustomerCardDrawer,
  useCustomerStore,
} from '../../store'

interface CustomerPanelProps {
  onShowSearchCustomer: (payload: string) => void
}

export const CustomerPanel = ({ onShowSearchCustomer }: CustomerPanelProps) => {
  const { t } = useTranslation()

  const customer = useCustomerStore((state) => state.mainInfo)

  const handleOpenCustomerDrawer = (payload: CustomerDTO['id'] | null) => {
    setCustomerCardId(payload)
    toggleCustomerCardDrawer(true)
  }

  if (!customer) {
    return (
      <Tooltip title={t('Customers.search')}>
        <IconButton onClick={() => onShowSearchCustomer('')} size="large">
          <SvgIcon>
            <PersonSearchIcon />
          </SvgIcon>
        </IconButton>
      </Tooltip>
    )
  }

  return (
    <ButtonGroup variant="outlined" color="info" size="small">
      <Tooltip title={t('Customers.search')}>
        <Button onClick={() => onShowSearchCustomer('')}>
          <SvgIcon>
            <PersonSearchIcon />
          </SvgIcon>
        </Button>
      </Tooltip>

      <Button onClick={() => handleOpenCustomerDrawer(customer.id)} startIcon={<ClientIcon />}>
        <Stack>
          <Typography component="span" variant="body2">
            {customer.phone}
          </Typography>
          <Typography
            component="span"
            variant="caption"
          >{`${customer.first_name ?? ''} ${customer.last_name ?? ''}`}</Typography>
        </Stack>
      </Button>
      <Button onClick={clearCustomerData}>
        <SvgIcon>
          <DeleteIcon />
        </SvgIcon>
      </Button>
    </ButtonGroup>
  )
}
