import { Outlet } from 'react-router-dom'
import { Box } from '@mui/material'
import * as Sentry from '@sentry/react'

import { CustomerDrawer, DrawerSearchCustomer } from '@klr/customers'
import { OrderDetailProvider } from '@klr/order-ticket-toolkit'
import { AlertError, Main, useBoolean, useResponsive, useSettings } from '@klr/shared'

import { DrawerDetail } from '../../components/DrawerDetail'
import { ModalDetail } from '../../components/ModalDetail'
import { environments } from '../../environments'
import { NavVertical } from '../components/NavVertical'

import { Header } from './components/Header'
import { NavMini } from './components/NavMini'

export const DashboardLayout = () => {
  const lgUp = useResponsive('up', 'lg')
  const { themeLayout } = useSettings()

  const [nav, toggleNav] = useBoolean()

  const renderContent = (
    <>
      <Sentry.ErrorBoundary fallback={(errorData) => <AlertError {...errorData} />}>
        <OrderDetailProvider marketplaceURL={environments.marketplaceURL}>
          <Outlet />
          <CustomerDrawer />
          <DrawerSearchCustomer />
        </OrderDetailProvider>
      </Sentry.ErrorBoundary>
      <DrawerDetail />
      <ModalDetail />
    </>
  )

  if (themeLayout === 'mini') {
    return (
      <>
        <Header onOpenNav={toggleNav.on} />

        <Box
          sx={{
            minHeight: 1,
            display: 'flex',
            flexDirection: { xs: 'column', lg: 'row' },
          }}
        >
          {lgUp ? (
            <NavMini />
          ) : (
            <NavVertical openNav={nav} onCloseNav={toggleNav.off} showToggleButton />
          )}

          <Main>{renderContent}</Main>
        </Box>
      </>
    )
  }

  return (
    <>
      <Header onOpenNav={toggleNav.on} />

      <Box
        sx={{
          minHeight: 1,
          display: 'flex',
          flexDirection: { xs: 'column', lg: 'row' },
        }}
      >
        <NavVertical openNav={nav} onCloseNav={toggleNav.off} showToggleButton />

        <Main>{renderContent}</Main>
      </Box>
    </>
  )
}
