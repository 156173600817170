import {
  SheetPlace,
  SheetPlaceEmpty,
  SheetTransport,
  StatusPlaceEnum,
  TicketDetail,
} from '@klr/api-connectors'
import { formatToFullDateTimeString } from '@klr/shared'

export const isPlaceNotEmpty = (value: SheetPlace | SheetPlaceEmpty): value is SheetPlace => {
  return (value as SheetPlace).ticket_id !== undefined
}

export const getAvailablePlace = (place: number): SheetPlaceEmpty => ({
  place,
  status: StatusPlaceEnum.AVAILABLE,
})

/**
 * Трансформація детального квитка в place для відомості
 *
 * @param ticketDetail
 */
export function generateSheetPlace(ticketDetail: TicketDetail): SheetPlace {
  return {
    belong_to_customer: false,
    place: ticketDetail.place,
    status: ticketDetail.status,
    ticket_id: ticketDetail.id,
    ticket_number: ticketDetail.ticket_number,
    order_id: ticketDetail.order_id,
    order_number: ticketDetail.order_number,
    can_reserve: ticketDetail.can_reserve,
    paid_form_id: ticketDetail.paid_form,
    paid_form_name: ticketDetail.display_paid_form_as,
    discount: ticketDetail.discount,
    discount_id: null,
    need_ticket_number: ticketDetail.need_ticket_number,
    order_date: formatToFullDateTimeString(new Date(ticketDetail.order_date)),
    paid_form_agent: null,
    // TODO: змінити
    is_early_booking: ticketDetail.is_early_booking,
    is_fixed_place_number: false,
    is_transfered_from_route: ticketDetail.is_transfered_from_route,
    is_valid_phone_number: false,
    is_partner: 0,
    first_name: ticketDetail.first_name,
    last_name: ticketDetail.last_name,
    phone: ticketDetail.phone,
    email: ticketDetail.email,
    price: ticketDetail.price,
    price_extra: 0,
    currency_id: ticketDetail.currency.id,
    currency_iso: ticketDetail.currency.iso,
    information: ticketDetail.information ?? '',

    station_from: ticketDetail.station_from,
    station_to: ticketDetail.station_to,
    date_departure: ticketDetail.trip?.date_start || '', // "HH:mm:ss DD.MM.YYYY"
    date_arriving: ticketDetail.trip?.date_stop || '', // "HH:mm:ss DD.MM.YYYY"

    city_from: ticketDetail.station_from?.city?.name || '',
    city_to: ticketDetail.station_to?.city?.name || '',
    country_from: ticketDetail.station_from?.city?.country_id ?? null,
    country_to: ticketDetail.station_to?.city?.country_id ?? null,
    full_city_from: ticketDetail.station_from?.city?.name || '',
    full_city_to: ticketDetail.station_to?.city?.name || '',
    full_country_from: ticketDetail.station_from?.city?.country_id ?? null,
    full_country_to: ticketDetail.station_to?.city?.country_id ?? null,
    is_price_hidden: 0,
    reconciled_at: ticketDetail.reconciled_at,
    driver_informed_at: null,
    last_notified_at: null,
    printed_at: '',
    confirmed: false,
  }
}

/**
 * Трансформація place в деталі квитка для відомості
 *
 * TODO: Не повністю заповниний об. якщо включити strict будуть помилки
 *
 * @param sheetPlace
 */
export function generateSheetTicket(sheetPlace: SheetPlace | undefined): TicketDetail | undefined {
  if (sheetPlace) {
    return {
      drivers_info: '',
      insurance: '',
      insurance_description: '',
      is_early_booking: sheetPlace.is_early_booking,
      is_free_place: false,
      is_transfered_from_route: sheetPlace.is_transfered_from_route,
      parent: null,
      transport_name: '',
      transport_reg_number: '',
      id: sheetPlace.ticket_id,
      order_id: sheetPlace.order_id,
      order_uuid: '',
      order_number: sheetPlace.order_number,
      ticket_number: sheetPlace.ticket_number,
      station_from: {
        ...sheetPlace.station_from,
        coordinates: '',
        is_active: 0,
        city: null,
      },
      station_to: {
        ...sheetPlace.station_to,
        coordinates: '',
        is_active: 0,
        city: null,
      },
      adjacent_ticket_ids: [],
      first_name: sheetPlace.first_name,
      need_ticket_number: 0,
      last_name: sheetPlace.last_name,
      email: sheetPlace.email,
      phone: sheetPlace.phone,
      liqpay_return_info: {
        returned_price: 0,
      },
      notifications: [],
      status: sheetPlace.status,
      information: sheetPlace.information,
      can_reserve: sheetPlace.can_reserve,
      paid_form: sheetPlace.paid_form_id,
      display_paid_form_as: sheetPlace.paid_form_name,
      order_date: sheetPlace.order_date,
      price: sheetPlace.price,
      price_extra: 0,
      discount: sheetPlace.discount,
      cashback_payment: 0,
      currency: {
        id: sheetPlace.currency_id,
        iso: sheetPlace.currency_iso,
        title: '',
        exchange_rate_to_uah: 1,
        updated_at: null,
      },
      // TODO: Перевірити чи правильно відображається
      trip: {} as TicketDetail['trip'],
      place: sheetPlace.place,
      seat_category: null,
      transport_id: 0,
      transport_gps_id: null,
      in_sublist: false,
      reconciled_at: null,
      driver_informed_at: null,
      reservation_expire_at: null,
      confirmed: false,
      children: null,
    }
  }
}
/**
 * Зливаємо конфліктних пасажирів в основну таблицю таким чином,
 * що на одному транспортному місці може бути декілька пасажирів, але на різних ділянках маршруту
 *
 * @param transport
 */
export const transformTransportPlaces = (transport: SheetTransport): SheetTransport => {
  const filterDeletedPlaces = (place: SheetPlace | SheetPlaceEmpty) =>
    place.status !== StatusPlaceEnum.DELETED

  if (transport.place_constructor && transport.conflicted?.length) {
    const allPlaces = [...transport.places, ...transport.conflicted]

    const formattedPlaces = allPlaces
      .filter(filterDeletedPlaces)
      .sort((first, second) => first.place - second.place)

    return {
      ...transport,
      places: formattedPlaces,
      conflicted: [],
    }
  }

  const formattedPlaces = transport.places.filter(filterDeletedPlaces)

  return {
    ...transport,
    places: formattedPlaces,
  }
}

export const getFormattedKey = (...params: (number | string | undefined)[]) =>
  params.every((item) => item !== undefined) && params.join('_')
