import React, { Fragment, useCallback, useEffect, useState } from 'react'
import { ExpandMore as ExpandMoreIcon, OpenInNew as OpenInNewIcon } from '@mui/icons-material'
import {
  Button,
  Collapse,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material'
import { styled } from '@mui/material/styles'
import { useQuery } from '@tanstack/react-query'
import { isValid } from 'date-fns'

import { apiOrdersFetchByPhone, OrderDetail, ordersQueryKeys } from '@klr/api-connectors'
import {
  AlertErrorFetch,
  Fallback,
  formatToDateString,
  formatToUkrDateTimeString,
  parseDateTimeString,
} from '@klr/shared'

import { paths } from '../../../../../router/paths'
import { TicketTabs } from '../../../../tickets/components/TicketTabs'

const StyledExtendedIconButton = styled(IconButton)(({ theme }) => ({
  transform: 'rotate(0deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest,
  }),
}))

interface OrderListProps {
  searchPhone: string
}

interface OrderWithCollapse extends OrderDetail {
  expanded: boolean
}

export const OrderList = ({ searchPhone }: OrderListProps) => {
  const [listOrders, setListOrders] = useState<OrderWithCollapse[]>([])

  const queryData = useQuery<OrderDetail[]>(
    ordersQueryKeys.ordersList({ ticket_phone: searchPhone }),
    async () => {
      const {
        data: { items },
      } = await apiOrdersFetchByPhone({
        phone: searchPhone,
      })

      return items
    },
    {
      enabled: !!searchPhone,
    }
  )

  useEffect(() => {
    if (queryData.data) {
      setListOrders((prev) => {
        return queryData.data.map((item, index) => ({
          ...item,
          expanded: item.id === prev[index]?.id ? prev[index].expanded : false,
        }))
      })
    }
  }, [queryData.data])

  const handleExpandClick = (id: OrderDetail['id']): void => {
    setListOrders((prev) =>
      prev.map((item) => (item.id === id ? { ...item, expanded: !item.expanded } : item))
    )
  }

  const getTripInfo = useCallback((orderDetail: OrderWithCollapse) => {
    let tripStart = ''
    let sheetLink = ''
    // order from aggregator may not have route id
    const sheetId = orderDetail?.tickets[0].trip?.route?.id

    if (orderDetail.trip_start_datetime) {
      const tripDate = parseDateTimeString(orderDetail.trip_start_datetime)

      if (isValid(tripDate)) {
        tripStart = formatToUkrDateTimeString(tripDate)
        sheetLink = sheetId
          ? `${paths.homeSheet}${sheetId}/?date=${formatToDateString(tripDate)}`
          : ''
      }
    }

    return { tripStart, sheetLink }
  }, [])

  if (queryData.isFetching) {
    return <Fallback />
  }

  if (queryData.error) {
    return <AlertErrorFetch />
  }

  if (queryData.data?.length === 0) {
    return <Typography>Не має пов'язаних поїздок.</Typography>
  }

  return (
    <List>
      {listOrders.map((orderDetail: OrderWithCollapse) => {
        const { tripStart, sheetLink } = getTripInfo(orderDetail)

        return (
          <Fragment key={orderDetail.id}>
            <ListItemButton onClick={() => handleExpandClick(orderDetail.id)}>
              <ListItemText primary={tripStart} />
              <ListItemSecondaryAction>
                <StyledExtendedIconButton
                  onClick={(event) => {
                    event.stopPropagation()
                    handleExpandClick(orderDetail.id)
                  }}
                  aria-expanded={orderDetail.expanded}
                  aria-label="show more"
                  size="large"
                  sx={orderDetail.expanded ? { transform: 'rotate(180deg)' } : null}
                >
                  <ExpandMoreIcon />
                </StyledExtendedIconButton>
              </ListItemSecondaryAction>
            </ListItemButton>
            <Divider />
            <Collapse in={orderDetail.expanded} timeout="auto" unmountOnExit>
              {sheetLink ? (
                <Grid container>
                  <Grid item xs={6} pt={2}>
                    <Button
                      href={sheetLink}
                      startIcon={<OpenInNewIcon />}
                      variant="outlined"
                      target="_blank"
                    >
                      Перейти в відомість
                    </Button>
                  </Grid>
                </Grid>
              ) : null}
              <TicketTabs
                tickets={orderDetail.tickets}
                orderId={orderDetail.uuid}
                orderStatus={orderDetail.status}
                tripStartDateTime={orderDetail.trip_start_datetime}
                tripEndDateTime={orderDetail.trip_end_datetime}
                showSendNotification
                isLoading={queryData.isLoading}
                fromList
              />
            </Collapse>
          </Fragment>
        )
      })}
    </List>
  )
}
