import { PayloadAction } from '@reduxjs/toolkit'

import {
  SheetPlace,
  SheetPlaceEmpty,
  SheetTransport,
  TicketResConfirm,
  TripTransportReqChangeStatusPlaces,
} from '@klr/api-connectors'

import { isPlaceNotEmpty } from '../helpers'
import { SheetsState } from '../sheetState'

type Options = 'places' | 'sub_sheet'

const options: Options[] = ['places', 'sub_sheet']

export const changeTicketsReducers = {
  changePrintAt: (
    state: SheetsState,
    action: PayloadAction<{ ticketId: number; printAt: string }>
  ) => {
    const idx = state.transports.findIndex(
      (t) => t.id === state.selectTransport?.id && t.trip.id === state.selectTransport.tripId
    )

    options.forEach((option) => {
      if (state.transports[idx][option as Options]) {
        state.transports[idx][option as Options] = state.transports[idx][option as Options]?.map(
          (p) =>
            isPlaceNotEmpty(p) && p.ticket_id === action.payload.ticketId
              ? { ...p, printed_at: action.payload.printAt }
              : p
        ) as SheetPlace[]
      }
    })
  },
  changeStatusPlaces: (
    state: SheetsState,
    action: PayloadAction<TripTransportReqChangeStatusPlaces>
  ) => {
    if (state.transports?.length) {
      const idx = state.transports.findIndex(
        (t) => t.id === action.payload.transport_id && t.trip.id === action.payload.trip_id
      )

      state.transports[idx].places = state.transports[idx].places.map(
        (place: SheetPlace | SheetPlaceEmpty) => {
          let changePlace = place

          action.payload.data.places.forEach((newPlace: SheetPlaceEmpty) => {
            if (place.place === newPlace.place) {
              changePlace = newPlace
            }
          })

          return changePlace
        }
      )
    }
  },
  changeConfirmParameter: (state: SheetsState, { payload }: PayloadAction<TicketResConfirm>) => {
    if (state.transports?.length) {
      state.transports.forEach((transport: SheetTransport) => {
        transport.places.forEach((item: SheetPlace | SheetPlaceEmpty, idx: number) => {
          if (isPlaceNotEmpty(item) && item.ticket_id === payload.ticket_id) {
            transport.places[idx] = {
              ...item,
              confirmed: payload.confirmed,
            }
          }
        })

        transport.sub_sheet?.forEach((item: SheetPlace, idx: number) => {
          if (item.ticket_id === payload.ticket_id) {
            if (transport.sub_sheet) {
              transport.sub_sheet[idx].confirmed = payload.confirmed
            }
          }
        })

        transport.conflicted?.forEach((item: SheetPlace, idx: number) => {
          if (item.ticket_id === payload.ticket_id) {
            if (transport.conflicted) {
              transport.conflicted[idx].confirmed = payload.confirmed
            }
          }
        })
      })
    }
  },
}
