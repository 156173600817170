import { useCallback } from 'react'
import { useDispatch } from 'react-redux'
import { Stack } from '@mui/material'

import { NSuccessMessage } from '@klr/api-connectors'
import { CallList } from '@klr/binotel'
import {
  CustomerPanel,
  DrawerSearchCustomer,
  setSearchCustomerPhoneNumber,
  toggleSearchCustomerDrawer,
} from '@klr/customers'

import { sheetsActions } from '../../../../../_modules_/sheets'
import { toggleSearchPassengerDrawer } from '../../../../../_modules_/sheets/store'

interface CustomerPanelProps {
  onShowSearchPassenger: (payload: string) => void
}

export const CustomerSection = ({ onShowSearchPassenger }: CustomerPanelProps) => {
  const dispatch = useDispatch()

  const handleCallListMessageInfo = useCallback(
    (notifications: NSuccessMessage[]) =>
      dispatch(
        sheetsActions.addMessageInfo({
          notifications,
        })
      ),
    [dispatch]
  )

  const showSearchCustomer = useCallback((payload: string) => {
    setSearchCustomerPhoneNumber(payload)
    toggleSearchCustomerDrawer(true)
    toggleSearchPassengerDrawer(false)
  }, [])

  return (
    <>
      <Stack direction="row" spacing={1} alignItems="center">
        <CallList
          onShowSearchPassenger={onShowSearchPassenger}
          onShowSearchCustomer={showSearchCustomer}
          onMessageInfo={handleCallListMessageInfo}
        />

        <CustomerPanel onShowSearchCustomer={showSearchCustomer} />
      </Stack>
      <DrawerSearchCustomer />
    </>
  )
}
