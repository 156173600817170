import { AxiosPromise } from 'axios'

import { axios } from '../../_axios'
import { DetailType, ListType } from '../../_types'
import {
  RouteReqFetchList,
  RoutesGroupDTO,
  RoutesGroupReqChange,
  RoutesGroupReqCreate,
  RoutesGroupResponse,
} from '../models'

export const apiRoutesGroupsFetchList = ({
  request: params,
}: RouteReqFetchList): AxiosPromise<ListType<RoutesGroupResponse>> => {
  return axios.get('/route-groups', { params })
}

export const apiRoutesGroupsDetailFetchList = (
  id: RoutesGroupDTO['id']
): AxiosPromise<DetailType<RoutesGroupDTO>> => {
  return axios.get(`/route-groups/${id}`)
}
export const apiRoutesGroupCreate = (
  data: RoutesGroupReqCreate
): AxiosPromise<DetailType<RoutesGroupDTO>> => {
  return axios.post('/route-groups', data)
}

export const apiRoutesGroupChange = ({
  id,
  data,
}: RoutesGroupReqChange): AxiosPromise<DetailType<RoutesGroupDTO>> => {
  return axios.put(`/route-groups/${id}`, data)
}

export const apiRoutesGroupsDeleteItem = (id: RoutesGroupDTO['id']): AxiosPromise => {
  return axios.delete(`/route-groups/${id}`)
}
