export const paths = {
  home: '/',
  app: '/app/',
  accountSettings: '/app/account/',

  mainDashboard: '/app/reports/dashboard/',

  // Dispatcher
  homeSheet: '/sheet/',
  sheetPrint: '/sheet-print',
  routeSheet: '/sheet/:id',

  // Users
  users: '/app/users/',
  userDetail: '/app/users/:id',
  userCreate: '/app/users/create/',

  // Agent
  agents: '/app/agents/',
  agentDetail: '/app/agents/:id',
  agentCreate: '/app/agents/create/',

  // Carriers
  carriers: '/app/carriers/',
  carrierDetail: '/app/carriers/:id',
  carrierDetailBuses: '/app/carriers/:id/buses/',
  carrierCreate: '/app/carriers/create/',

  carrierBuses: '/app/buses/',

  // Dispatchers
  dispatchers: '/app/dispatchers/',
  dispatcherEdit: '/app/dispatchers/:id',
  dispatcherCreate: '/app/dispatchers/create/',
  dispatcherRoutesGroups: '/app/dispatchers/routes-groups',

  // Routes
  routesList: '/app/routes/',
  routesEdit: '/app/routes/:id',
  routesCreate: '/app/routes/create',

  // Route Directions
  routeDirectionList: '/app/route-directions/',
  routeDirectionEdit: '/app/route-directions/:id',

  // Finance
  financePromoCodes: '/app/finance/promo-code/',
  financePromotions: '/app/finance/promotions/',
  financeDynamicFareRules: '/app/finance/dynamic-fare-rules/',
  financePaymentMethod: '/app/finance/payment-method/',
  financePaidForms: '/app/finance/paid-forms',

  // Settings
  settingRuleAffiliateProgram: '/app/settings/rule-affiliate-program/',
  settingsSystemLocation: '/app/settings/system-location/',
  settingsTransportOwners: '/app/settings/transport-owners/',
  settingsSorryBonusTemplates: '/app/settings/sorry-bonus-templates/',
  settingsTemplates: '/app/settings/templates/',
  settingsTags: '/app/settings/tags/',
  settingsNotificationClients: '/app/settings/notification-clients/',
  settingsTelegramData: '/app/settings/telegram-id/',
  settingsCurrencies: '/app/settings/currencies/',
  settingsBranches: '/app/settings/branches/',

  // Notifications
  notifications: '/app/notifications/',

  // Actions Logging
  actionsLoggingList: '/app/actions-logging/',

  // Reports
  reportsCarriers: '/app/reports/carriers',
  reportsAgents: '/app/reports/agents',
  orderList: '/app/order-list/',
  reportsPartnerProgram: '/app/reports/partner-program/',
  reportsPartnerProgramDetails: '/app/reports/partner-program/:id',
  reportsRouteAnalytics: '/app/bus-trip-analysis/',

  // Dashboard
  dashboardTrafficWorkload: '/app/dashboard/traffic-workload/',

  // Error
  error404: '/404',
}
