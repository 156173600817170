import { useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useDispatch } from 'react-redux'

import { OrderDetail, TicketReqReturn } from '@klr/api-connectors'
import { useConfirm } from '@klr/shared'

import { ticketsActionsAsync } from '../index'

import { useDeleteTicket } from './useDeleteTicket'
import { useReturnTicket } from './useReturnTicket'

type Props = {
  ticketId: number
  ticketNumber: string
  orderId: OrderDetail['id']
  isSheet?: boolean
}

type HookReturn = {
  handleRemoveTicket: VoidFunction
  handlePrintPdf: VoidFunction
  handleReturnTicket: (val?: number | null) => void
  handleCustomReturnPercent: (percent: number) => void
  isLoading: boolean
}

export function useHandlers({
  ticketId,
  ticketNumber,
  orderId,
  isSheet = false,
}: Props): HookReturn {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const confirm = useConfirm()
  const { mutate: returnTicket, isLoading } = useReturnTicket(orderId)
  const { mutate: removeTicket, isLoading: isDeleting } = useDeleteTicket({
    ticketId,
    ticketNumber,
    orderId,
    isSheet,
  })

  const handleRemoveTicket = () => {
    confirm({
      title: t('Messages.sure_should_return_ticket'),
      description: t('Messages.will_whole_returning'),
    }).then((success) => {
      if (success) {
        removeTicket()
      }
    })
  }

  const handlePrintPdf = useCallback(() => {
    dispatch(
      ticketsActionsAsync.printPdfTicketAsync({
        ticketId,
        isSheet,
      })
    )
  }, [dispatch, isSheet, ticketId])

  // Повернути квиток
  const handleReturnTicket = (percent: number | null = null) => {
    const request: TicketReqReturn = {
      ticketId,
    }

    let message = t('Messages.return_according_to_the_return_rules')

    if (percent) {
      request.data = {
        percent,
      }

      message = t('Messages.will_partial_returning', { percent })
    }

    confirm({
      title: t('Messages.should_return_ticket'),
      description: message,
    }).then((success) => {
      if (success) {
        returnTicket(request)
      }
    })
  }

  const handleCustomReturnPercent = useCallback(
    (percent: number) => {
      dispatch(
        ticketsActionsAsync.customReturnPercent({
          ticketId,
          data: {
            percent,
          },
        })
      )
    },
    [dispatch, ticketId]
  )

  return {
    handleRemoveTicket,
    handlePrintPdf,
    handleReturnTicket,
    handleCustomReturnPercent,
    isLoading: isLoading || isDeleting,
  }
}
