import { createSlice, PayloadAction } from '@reduxjs/toolkit'

import {
  SheetPlaceTransfer,
  TicketDetail,
  TicketReqCreate,
  TicketReqCustomReturnPercent,
  TicketReqFetchSeatsForTransfer,
  TicketReqTransfer,
  TicketReturnCondition,
} from '@klr/api-connectors'

import {
  PayloadActionToggleSubList,
  PayloadDelete,
  PrintPdfTicketAsync,
  TicketsActionTypes,
  TicketsState,
  types,
} from './types'

const initialState: TicketsState = {
  detail: null,
  loading: false,
  availablePlaces: [],
  returnCondition: null,
  loadingCondition: false,
}

const slice = createSlice({
  name: 'TICKETS',
  initialState,
  reducers: {
    saveDetail: (state: TicketsState, action: PayloadAction<TicketDetail>) => {
      state.detail = action.payload
    },
    saveAvailablePlaces: (state: TicketsState, action: PayloadAction<number[]>) => {
      state.availablePlaces = action.payload
    },
    saveReturnCondition: (state: TicketsState, action: PayloadAction<TicketReturnCondition>) => {
      state.returnCondition = action.payload
    },
    clearReturnCondition: (state: TicketsState) => {
      state.returnCondition = null
    },
    clearAvailablePlaces: (state: TicketsState) => {
      state.availablePlaces = []
    },
    clearDetail: (state: TicketsState) => {
      state.detail = null
    },
    toggleLoading: (state: TicketsState, action: PayloadAction<boolean>) => {
      state.loading = action.payload
    },
    toggleLoadingCondition: (state: TicketsState, action: PayloadAction<boolean>) => {
      state.loadingCondition = action.payload
    },
  },
})

export default slice.reducer

// Action Creators Async
export const ticketsActions = slice.actions

export const ticketsActionsAsync = {
  fetchDetailAsync: (payload: number | string): TicketsActionTypes => ({
    type: types.FETCH_DETAIL,
    payload,
  }),
  createAsync: (payload: {
    isSheet: boolean
    requests: TicketReqCreate[]
  }): TicketsActionTypes => ({
    type: types.CREATE_ITEM,
    payload,
  }),
  toggleSubListAsync: (payload: PayloadActionToggleSubList): TicketsActionTypes => ({
    type: types.TOGGLE_SUB_LIST,
    payload,
  }),
  deleteAsync: (payload: PayloadDelete): TicketsActionTypes => ({
    type: types.DELETE_ITEM,
    payload,
  }),
  printPdfTicketAsync: (payload: PrintPdfTicketAsync['payload']): TicketsActionTypes => ({
    type: types.PRINT_PDF_TICKET,
    payload,
  }),
  fetchSeatsForTransfer: (payload: {
    isSheet: boolean
    request: TicketReqFetchSeatsForTransfer
  }): TicketsActionTypes => ({
    type: types.FETCH_SEATS_FOR_TRANSFER,
    payload,
  }),
  fetchAvailableSeatsAsync: (payload: number): TicketsActionTypes => ({
    type: types.FETCH_AVAILABLE_SEATS,
    payload,
  }),
  transferAsync: (payload: {
    isSheet?: boolean
    oldTransportId: number
    oldTripId: number
    place: SheetPlaceTransfer
    request: TicketReqTransfer
  }): TicketsActionTypes => ({
    type: types.TRANSFER,
    payload,
  }),
  fetchReturnConditionsAsync: (payload: number): TicketsActionTypes => ({
    type: types.FETCH_RETURN_CONDITIONS,
    payload,
  }),
  customReturnPercent: (payload: TicketReqCustomReturnPercent): TicketsActionTypes => ({
    type: types.CUSTOM_RETURN_PERCENT,
    payload,
  }),
}
