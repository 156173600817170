export function isEmpty<T>(value: T, isZeroEmpty: boolean): boolean {
  return Boolean(value) || (!isZeroEmpty && value === 0) || value === false
}

export function filterOnCondition<T extends object>(
  inputObj: T,
  condition: (value: unknown) => boolean
): Partial<T> {
  const filteredObj: Partial<T> = {}

  for (const key in inputObj) {
    if (Object.prototype.hasOwnProperty.call(inputObj, key) && condition(inputObj[key])) {
      filteredObj[key] = inputObj[key]
    }
  }

  return filteredObj
}

export function filterNonEmptyProperties<T extends object>(inputObj: T): Partial<T> {
  return filterOnCondition(inputObj, (value) => isEmpty(value, false))
}

export function filterNonZeroValues<T extends object>(inputObj: T): Partial<T> {
  return filterOnCondition(inputObj, (value) => isEmpty(value, true))
}
