import { lazy } from 'react'
import { Navigate, Outlet, useRoutes } from 'react-router-dom'

import { SystemRoleMap } from '@klr/api-connectors'
import { AuthGuard, authRoutes, RoleBasedGuard } from '@klr/auth'
import { bookingRoutes } from '@klr/booking'
import { customerReferralsRoutes, customerRoutes } from '@klr/customers'
import { schemesRoutes } from '@klr/schemes'
import { Loadable, sharedRoutes } from '@klr/shared'

import { DashboardLayout } from '../layouts/dashboard'
import { SheetLayout } from '../layouts/sheet'
import { useFeaturesStore } from '../useFeaturesStore'

import RouteManager from './RouteManager'

const routeManager = { element: <RouteManager />, index: true }

export default function Router() {
  const { features } = useFeaturesStore()
  return useRoutes([
    {
      path: '/',
      element: (
        <AuthGuard>
          <RouteManager />
        </AuthGuard>
      ),
    },
    sharedRoutes,
    {
      path: 'sheet',
      element: (
        <AuthGuard>
          <RoleBasedGuard roles={[SystemRoleMap.Dispatcher, SystemRoleMap.Accountant]}>
            <SheetLayout />
          </RoleBasedGuard>
        </AuthGuard>
      ),
      children: [
        { element: <BusTripSheetSearchPage />, index: true },
        { path: ':id', element: <BusTripSheetDetailPage /> },
      ],
    },
    {
      path: 'sheet-print',
      element: (
        <RoleBasedGuard roles={[SystemRoleMap.Dispatcher, SystemRoleMap.Accountant]}>
          <BusTripSheetPrintPage />
        </RoleBasedGuard>
      ),
    },
    {
      path: 'app',
      element: (
        <AuthGuard>
          <DashboardLayout />
        </AuthGuard>
      ),
      children: [
        routeManager,
        { path: 'account/settings', element: <AccountPage /> },
        {
          path: bookingRoutes.path,
          element: <RoleBasedGuard roles={[SystemRoleMap.Dispatcher]} children={<Outlet />} />,
          children: bookingRoutes.children,
        },
        {
          path: 'order-list',
          element: (
            <RoleBasedGuard roles={[SystemRoleMap.Dispatcher, SystemRoleMap.Accountant]}>
              <OrderListPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'bus-trip-analysis',
          element: (
            <RoleBasedGuard roles={[SystemRoleMap.Dispatcher, SystemRoleMap.Accountant]}>
              <BusTripAnalysisPage />
            </RoleBasedGuard>
          ),
        },
        {
          path: 'routes',
          element: (
            <RoleBasedGuard
              roles={[SystemRoleMap.Carrier, SystemRoleMap.Dispatcher]}
              children={<Outlet />}
            />
          ),
          children: [
            { element: <RouteListPage />, index: true },
            { path: 'create', element: <RouteCreatePage /> },
            { path: ':id', element: <RouteEditPage /> },
          ],
        },
        features?.show_traffic_workload
          ? {
              path: 'dashboard',
              children: [
                {
                  path: 'traffic-workload',
                  element: (
                    <RoleBasedGuard
                      roles={[SystemRoleMap.Dispatcher, SystemRoleMap.Accountant]}
                      children={<Outlet />}
                    />
                  ),
                  children: [{ element: <TrafficWorkloadPage />, index: true }],
                },
              ],
            }
          : {},
        {
          path: 'agents',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [
            { element: <AgentListPage />, index: true },
            { path: 'create', element: <AgentCreatePage /> },
            { path: ':id', element: <AgentEditPage /> },
          ],
        },

        {
          path: 'users',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [
            { element: <UserListPage />, index: true },
            { path: 'create', element: <UserCreatePage /> },
            { path: ':id', element: <UserEditPage /> },
          ],
        },

        {
          path: 'carriers',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [
            { element: <CarrierListPage />, index: true },
            { path: 'create', element: <CarrierCreatePage /> },
            { path: ':id', element: <CarrierEditPage /> },
            { path: ':id/buses', element: <CarrierBusesListPage /> },
          ],
        },
        {
          path: 'buses',
          element: (
            <RoleBasedGuard roles={[SystemRoleMap.Carrier, SystemRoleMap.Dispatcher]}>
              <CarrierBusesListPage />
            </RoleBasedGuard>
          ),
        },

        {
          path: 'dispatchers',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [
            { element: <DispatchersListPage />, index: true },
            { path: 'create', element: <DispatcherCreatePage /> },
            { path: ':id', element: <DispatcherEditPage /> },
            {
              path: 'routes-groups',
              element: <DispatcherRouteGroupConnectionsPage />,
            },
          ],
        },

        {
          path: 'finance',
          children: [
            routeManager,
            { path: 'promo-code', element: <FinancePromoCodesPage /> },
            { path: 'payment-method', element: <FinancePaymentMethodListPage /> },

            { path: 'dynamic-fare-rules', element: <FinanceDynamicFareRulesPage /> },
            { path: 'promotions', element: <FinancePromotionListPage /> },
            { path: 'paid-forms', element: <FinancePaidForms /> },
          ],
        },
        {
          path: 'settings',
          children: [
            { path: 'rule-affiliate-program', element: <SettingRuleAffiliateProgramListPage /> },
            { path: 'system-location', element: <SettingSystemLocationPage /> },
            { path: 'transport-owners', element: <SettingTransportOwnerListPage /> },
            { path: 'sorry-bonus-templates', element: <SettingSorryBonusTemplateListPage /> },
            { path: 'templates', element: <SettingSmsTemplateListPage /> },
            { path: 'tags', element: <SettingTagListPage /> },
            {
              path: schemesRoutes.path,
              element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
              children: schemesRoutes.children,
            },
            { path: 'notification-clients', element: <SettingNotificationPartnerPage /> },
            { path: 'telegram-id', element: <SettingTelegramDataPage /> },
            { path: 'currencies', element: <SettingCurrencyListPage /> },
            { path: 'branches', element: <SettingBranchListPage /> },
          ],
        },
        {
          path: 'route-directions',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [
            { element: <RouteDirectionsPage />, index: true },
            { path: ':id', element: <RouteDirectionEditPage /> },
          ],
        },
        {
          path: 'notifications',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [{ element: <NotificationListPage />, index: true }],
        },
        {
          path: 'actions-logging',
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: [{ element: <ActionsLoggingListPage />, index: true }],
        },
        {
          path: 'reports',
          element: <RoleBasedGuard roles={[SystemRoleMap.Accountant]} children={<Outlet />} />,
          children: [
            routeManager,
            { path: 'carriers', element: <ReportCarriersPage /> },
            { path: 'agents', element: <ReportAgentsPage /> },
            {
              path: 'partner-program',
              element: <PartnerProgramPage />,
            },
            { path: 'partner-program/:id', element: <PartnerProgramDetailPage /> },
          ],
        },
        {
          path: customerReferralsRoutes.path,
          element: <RoleBasedGuard roles={[SystemRoleMap.Dispatcher]} children={<Outlet />} />,
          children: customerReferralsRoutes.children,
        },
        {
          path: customerRoutes.path,
          element: <RoleBasedGuard roles={[SystemRoleMap.Admin]} children={<Outlet />} />,
          children: customerRoutes.children,
        },
      ],
    },
    authRoutes,
    { path: '*', element: <Navigate to="404" replace /> },
  ])
}

// BUS TRIP
const BusTripSheetSearchPage = Loadable(lazy(() => import('../pages/sheet/search')))
const BusTripSheetDetailPage = Loadable(lazy(() => import('../pages/sheet/detail')))
const BusTripSheetPrintPage = Loadable(lazy(() => import('../pages/sheet/print')))
const BusTripAnalysisPage = Loadable(lazy(() => import('../pages/app/bus-trip-analysis')))
// ACCOUNT
const AccountPage = Loadable(lazy(() => import('../pages/app/account')))
// PARTNER PROGRAM
const PartnerProgramPage = Loadable(lazy(() => import('../pages/app/partner-program/index')))
const PartnerProgramDetailPage = Loadable(lazy(() => import('../pages/app/partner-program/detail')))
// FINANCE
const FinancePaymentMethodListPage = Loadable(
  lazy(() => import('../pages/app/finance/payment-method-list'))
)
const FinancePromoCodesPage = Loadable(lazy(() => import('../pages/app/finance/promo-codes')))
const FinancePromotionListPage = Loadable(lazy(() => import('../pages/app/finance/promotion-list')))
const FinanceDynamicFareRulesPage = Loadable(
  lazy(() => import('../pages/app/finance/dynamic-fare-rules'))
)
const FinancePaidForms = Loadable(lazy(() => import('../pages/app/finance/paid-forms')))
// USERS
const UserListPage = Loadable(lazy(() => import('../pages/app/users/list')))
const UserCreatePage = Loadable(lazy(() => import('../pages/app/users/create')))
const UserEditPage = Loadable(lazy(() => import('../pages/app/users/edit')))

// DISPATCHERS
const DispatchersListPage = Loadable(lazy(() => import('../pages/app/dispatchers/list')))
const DispatcherCreatePage = Loadable(lazy(() => import('../pages/app/dispatchers/create')))
const DispatcherEditPage = Loadable(lazy(() => import('../pages/app/dispatchers/edit')))
const DispatcherRouteGroupConnectionsPage = Loadable(
  lazy(() => import('../pages/app/dispatchers/route-group-connections'))
)
// AGENTS
const AgentListPage = Loadable(lazy(() => import('../pages/app/agents/list')))
const AgentCreatePage = Loadable(lazy(() => import('../pages/app/agents/create')))
const AgentEditPage = Loadable(lazy(() => import('../pages/app/agents/edit')))
// CARRIERS
const CarrierListPage = Loadable(lazy(() => import('../pages/app/carriers/list')))
const CarrierCreatePage = Loadable(lazy(() => import('../pages/app/carriers/create')))
const CarrierEditPage = Loadable(lazy(() => import('../pages/app/carriers/edit')))
const CarrierBusesListPage = Loadable(lazy(() => import('../pages/app/carriers/busses/list')))
// ROUTES
const RouteListPage = Loadable(lazy(() => import('../pages/app/routes')))
const RouteCreatePage = Loadable(lazy(() => import('../pages/app/routes/create')))
const RouteEditPage = Loadable(lazy(() => import('../pages/app/routes/edit')))
// SETTINGS
const SettingSystemLocationPage = Loadable(
  lazy(() => import('../pages/app/settings/system-location'))
)
const SettingTransportOwnerListPage = Loadable(
  lazy(() => import('../pages/app/settings/transport-owner-list'))
)
const SettingSorryBonusTemplateListPage = Loadable(
  lazy(() => import('../pages/app/settings/sorry-bonus-template-list'))
)
const SettingSmsTemplateListPage = Loadable(
  lazy(() => import('../pages/app/settings/sms-template-list'))
)
const SettingTagListPage = Loadable(lazy(() => import('../pages/app/settings/tag-list')))
const SettingRuleAffiliateProgramListPage = Loadable(
  lazy(() => import('../pages/app/settings/rule-affiliate-program-list'))
)
const SettingNotificationPartnerPage = Loadable(
  lazy(() => import('../pages/app/settings/notification-partner'))
)
const SettingTelegramDataPage = Loadable(lazy(() => import('../pages/app/settings/telegram-data')))
const NotificationListPage = Loadable(lazy(() => import('../pages/app/notifications')))
const ActionsLoggingListPage = Loadable(lazy(() => import('../pages/app/actions-logging')))
const OrderListPage = Loadable(lazy(() => import('../pages/app/order-list')))
const ReportCarriersPage = Loadable(lazy(() => import('../pages/app/reports/carriers')))
const ReportAgentsPage = Loadable(lazy(() => import('../pages/app/reports/agents')))
const SettingCurrencyListPage = Loadable(lazy(() => import('../pages/app/settings/currency-list')))
const SettingBranchListPage = Loadable(lazy(() => import('../pages/app/settings/branch-list')))
// ROUTE DIRECTIONS
const RouteDirectionsPage = Loadable(lazy(() => import('../pages/app/route-directions/list')))
const RouteDirectionEditPage = Loadable(lazy(() => import('../pages/app/route-directions/edit')))
// DASHBOARD
const TrafficWorkloadPage = Loadable(lazy(() => import('../pages/app/dashboard/traffic-workload')))
