import React from 'react'
import { Tab, Tabs, Typography } from '@mui/material'

import { OrderDetail, StatusTicketEnum } from '@klr/api-connectors'
import { TabPanel } from '@klr/shared'

import { TicketDetailView } from '../TicketDetailView'

interface TicketTabsProps {
  tripStartDateTime: OrderDetail['trip_start_datetime']
  tripEndDateTime: OrderDetail['trip_end_datetime']
  tickets: OrderDetail['tickets']
  showSendMessage?: boolean
  showSendNotification?: boolean
  orderId: OrderDetail['uuid']
  orderStatus: StatusTicketEnum
  isLoading: boolean
  fromList?: boolean
}

export const TicketTabs = ({
  tickets,
  tripEndDateTime,
  tripStartDateTime,
  showSendMessage = false,
  showSendNotification = false,
  orderId,
  orderStatus,
  isLoading,
  fromList = false,
}: TicketTabsProps) => {
  const [currentTab, setCurrentTab] = React.useState(0)

  const handleTabsChange = (e: React.SyntheticEvent, value: number): void => {
    setCurrentTab(value)
  }

  return (
    <div>
      <Tabs
        value={currentTab}
        onChange={handleTabsChange}
        variant="scrollable"
        scrollButtons="auto"
      >
        {tickets.map((tab) => (
          <Tab
            key={tab.id}
            label={<Typography variant="caption">Квиток - №{tab.ticket_number}</Typography>}
          />
        ))}
      </Tabs>

      {tickets.map((ticketData, idx) => (
        <TabPanel key={ticketData.id} currentTab={currentTab} value={idx}>
          <TicketDetailView
            dataSource={ticketData}
            showSendMessage={showSendMessage}
            tripStartDateTime={tripStartDateTime}
            tripEndDateTime={tripEndDateTime}
            showSendNotification={showSendNotification}
            orderId={orderId}
            orderStatus={orderStatus}
            isLoading={isLoading}
            fromList={fromList}
          />
        </TabPanel>
      ))}
    </div>
  )
}

TicketTabs.displayName = 'TicketTabs'
