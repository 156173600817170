import { ReactNode } from 'react'
import { ArrowLeft as ArrowLeftIcon, ArrowRight as ArrowRightIcon } from 'react-feather'
import { useTranslation } from 'react-i18next'
import {
  Button,
  Card,
  CardHeader,
  Checkbox,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  SvgIcon,
} from '@mui/material'
import { styled } from '@mui/material/styles'

import { CurrencyDTO, SystemRole } from '@klr/api-connectors'
import { Fallback } from '@klr/shared'

import { useTransferList } from './useTransferList'

const StyledList = styled(List)(({ theme }) => ({
  height: 250,
  backgroundColor: theme.palette.background.paper,
  overflow: 'auto',
}))

type PropsType<T> = {
  settingData: T[]
  userData: T[]
  setUserData: (value: T[]) => void
  leftTitle: string
  rightTitle: string
  renderListItem: (item: T, labelId: string) => ReactNode
}

export const TransferLists = ({
  settingData,
  userData,
  setUserData,
  leftTitle,
  rightTitle,
  renderListItem,
}: PropsType<CurrencyDTO | SystemRole>) => {
  const { t } = useTranslation()

  const {
    notSelected,
    leftChecked,
    rightChecked,
    checked,
    numberOfChecked,
    handleToggleAll,
    handleToggle,
    handleCheckedRight,
    handleCheckedLeft,
  } = useTransferList({
    settingData,
    userData,
    setUserData,
  })

  const ItemList = (title: string, items: (CurrencyDTO | SystemRole)[]) => (
    <Card>
      <CardHeader
        avatar={
          <Checkbox
            onClick={handleToggleAll(items)}
            checked={numberOfChecked(items) === items.length && items.length !== 0}
            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
            disabled={items.length === 0}
            inputProps={{ 'aria-label': 'all items selected' }}
          />
        }
        title={title}
        subheader={`${numberOfChecked(items)}/${items.length} вибрано`}
        sx={{ py: 1, px: 2 }}
      />
      <Divider />
      <StyledList dense>
        {items.map((item) => {
          const labelId = `transfer-list-all-item-${item.id}-label`

          return (
            <ListItemButton key={item.id} onClick={handleToggle(item)}>
              <ListItemIcon>
                <Checkbox
                  checked={checked.includes(item)}
                  tabIndex={-1}
                  disableRipple
                  inputProps={{ 'aria-labelledby': labelId }}
                />
              </ListItemIcon>
              {renderListItem(item, labelId)}
            </ListItemButton>
          )
        })}
        <ListItem />
      </StyledList>
    </Card>
  )

  if (!userData) {
    return <Fallback />
  }

  return (
    <Grid container>
      <Grid item xs={5}>
        {ItemList(leftTitle, notSelected)}
      </Grid>
      <Grid item container direction="column" alignItems="center" justifyContent="center" xs={2}>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedRight}
          disabled={leftChecked.length === 0}
          aria-label={t('Common.add')}
          sx={{ my: 0.5, mx: 0 }}
        >
          <SvgIcon fontSize="small">
            <ArrowRightIcon />
          </SvgIcon>
        </Button>
        <Button
          variant="outlined"
          size="small"
          onClick={handleCheckedLeft}
          disabled={rightChecked.length === 0}
          aria-label={t('Common.delete')}
          sx={{ my: 0.5, mx: 0 }}
        >
          <SvgIcon fontSize="small">
            <ArrowLeftIcon />
          </SvgIcon>
        </Button>
      </Grid>
      <Grid item xs={5}>
        {ItemList(rightTitle, userData)}
      </Grid>
    </Grid>
  )
}
